@import '../../../../styles/variables';

.#{$vip-prefix}-questions {
  padding: 24px;

  &__spinner {
    text-align: center;
    margin-bottom: 24px;

    .andes-spinner__container {
      display: inline-block;
      position: relative;
    }
  }

  &__title {
    margin: 12px 0;
    font-size: $font-size-24;
    font-weight: $font-weight-semibold;
  }

  &__subtitle {
    padding: 4px 16px 0;
  }
}
