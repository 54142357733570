@import '../../../styles/variables';

.ui-pdp-compatibilities__modal {
  /* stylelint-disable-next-line no-invalid-position-at-import-rule */
  @import '~@andes/list/index';
}

.ui-pdp-compatibilities {
  .andes-message__content {
    line-height: 50px;
    position: relative;
    width: 90%;
  }

  .ui-pdp-action-modal {
    position: absolute;
    margin-top: 2px;
    right: 0;
    top: 0;
  }

  .andes-message__text {
    max-width: 75%;
  }

  &__text {
    line-height: $font-size-18;
    margin-top: $andes-spacing-16;
    margin-bottom: $andes-spacing-16;
  }

  .andes-badge--notification {
    margin-top: 16px;
  }

  &__title {
    margin-bottom: $andes-spacing-16;
  }

  &__feature {
    text-transform: uppercase;
    margin-bottom: -$andes-spacing-4;
    margin-left: $andes-spacing-8;
  }

  &__form {
    display: flex;
    margin-top: $andes-spacing-20;
    margin-bottom: $andes-spacing-20;
  }

  &__message,
  &__results {
    margin-top: $andes-spacing-20;
    margin-bottom: $andes-spacing-20;
  }

  &__row--even {
    background-color: $andes-bg-secondary;
  }

  &__input {
    flex: 1;
    margin-left: $andes-spacing-12;
    min-width: 18%;
    max-width: 18%;

    &:first-child {
      margin-left: 0;
    }

    &.andes-dropdown.andes-dropdown--form .andes-dropdown__trigger {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 14px;
      margin-bottom: 0;
    }
  }

  &__submit {
    flex: 1;
    margin-left: $andes-spacing-20;
    margin-top: $andes-spacing-20;
  }

  /* stylelint-disable-next-line no-duplicate-selectors */
  &__modal {
    max-width: 100%;
    width: 840px;
    height: auto;
    max-height: 80vh;

    .andes-dropdown .andes-floating-menu {
      max-height: 200px;
      overflow-y: auto;
      z-index: 11;
      top: 69px;
    }

    .ui-pdp-action-modal-content__container {
      padding-bottom: $andes-spacing-20;
    }

    .andes-modal {
      &__container {
        position: relative;
      }

      &__wrapper {
        height: auto;
        width: 100%;
        max-height: 80vh;
        overflow: auto;
      }

      &__content {
        padding: 0 $andes-spacing-64 $andes-spacing-64;
        min-height: 20em;
      }
    }
  }

  &__modal-description {
    margin-bottom: $andes-spacing-20;
  }
}

.ui-pdp-compatibilities__submit .andes-spinner__icon {
  width: 18px;
  height: 18px;
}

.ui-pdp-compatibilities__modal .andes-table--sticky .andes-table__header {
  top: -59px;
}
