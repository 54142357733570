.ui-vip-core.ui-vip-core-mshops,
.ui-pdp.ui-pdp-mshops {
  &-container--explorer,
  &-container--legend,
  &-container--simple {
    .ui-recommendations-carousel-snapped {
      .ui-recommendations-carousel-snapped__header {
        margin-bottom: $andes-spacing-32;

        > .ui-recommendations-carousel-snapped__header-titles
          > .ui-recommendations-title
          > .ui-recommendations-title-link {
          font-weight: $font-weight-bold;
        }
      }

      .ui-recommendations-card__content {
        .ui-recommendations-card__title {
          color: $andes-text-color-secondary;
          font-size: $font-size-16;
          margin-bottom: $andes-spacing-8;
        }

        .ui-recommendations-card__price-block {
          margin-bottom: $andes-spacing-8;

          .ui-recommendations-card__price-top {
            margin-bottom: $andes-spacing-8;

            .ui-recommendations-card__price-original-price {
              font-size: $font-size-14 !important;
              margin-bottom: $andes-spacing-4;
            }

            .ui-recommendations-card__price-and-discount .ui-recommendations-card__price {
              font-size: $font-size-20 !important;
            }
          }

          .ui-recommendations-card__installments {
            .ui-recommendations-card__installments-price--free,
            .andes-money-amount {
              color: $andes-text-color-secondary;
              font-size: $font-size-12 !important;
            }
          }
        }
      }
    }
  }

  &-container--explorer,
  &-container--legend {
    .andes-carousel-snapped__control svg path {
      fill: $andes-black;
    }
  }

  &-container--simple {
    .andes-carousel-snapped__control svg path {
      fill: $andes-gray-900;
    }
  }

  &-container--explorer {
    .andes-carousel-snapped__control {
      &--next,
      &--previous {
        height: $andes-spacing-48;
        width: $andes-spacing-48;

        svg {
          width: $andes-spacing-24;
        }
      }

      &--next {
        right: -($andes-spacing-20);
      }

      &--previous {
        left: -($andes-spacing-20);
      }
    }
  }

  &-container--legend {
    .ui-recommendations-card.ui-recommendations-card--horizontal,
    .andes-carousel-snapped__control {
      border: none;
      box-shadow: none;

      &--next,
      &--previous {
        width: $andes-spacing-16;
      }

      &--next {
        right: -($andes-spacing-16);
      }

      &--previous {
        left: -($andes-spacing-16);
      }
    }
  }
}
