.ui-vip-shipping-message .andes-button--small {
  margin-top: 10px;
}

.ui-vip-short-description-modal.andes-modal.andes-modal--small .andes-modal__scroll {
  overflow-y: hidden;
}

.ui-vip-short-description-modal.andes-modal.andes-modal--small .andes-modal__header {
  padding: 20px 48px 0;
}
