.ui-vip-grouped-header {
  margin-top: $andes-spacing-12;
  width: 100%;
  display: flex;

  &__header-store {
    background-color: $andes-bg-primary;
    padding: $andes-spacing-16 $andes-spacing-24;
    border-radius: 4px 4px 0 0;
    position: absolute;
    bottom: -190px;
    z-index: 2;
  }

  .ui-pdp-breadcrumb {
    margin-right: auto;
  }

  .ui-pdp-share {
    position: relative;
  }

  .ui-pdp-eyi {
    margin-top: -1px;
  }
}
