$caption-color: #999;
$border-color-sr: #eee;
$title-color-sr: #000;

.advertising {
  padding-bottom: 22px;
}

.#{$vpp-prefix}-advertising {
  margin-left: auto;
  margin-right: auto;
}

.#{$vpp-prefix}-advertising > .caption {
  color: $caption-color;
  display: inline-block;
  font-size: $font-size-14;
  text-align: right;
  width: 300px;
  line-height: 1.9;
}

.container-advertising .ui-related-services {
  width: 330px;
}

.container-advertising {
  width: 100%;
}

.container-advertising.ui-related-services.loaded {
  border-bottom: 1px solid $border-color-sr;
  width: 100%;
  padding: 32px 15px;
}

.ui-related-services.loaded.line {
  margin: 16px 0;
  border-bottom: 1px solid $border-color-sr;
}

.title-advertising {
  font-size: 16px;
  padding-bottom: 32px;
  color: $title-color-sr;
  opacity: 0.8;
}

.banner.ui-vpp-advertising.loaded {
  text-align: center;
}

.banner.ui-vpp-advertising.banner--hidden {
  display: none;
}
