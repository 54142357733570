.ui-pdp-container__row--compats-feedback {
  margin-bottom: 8px;

  [class*='andes-message__close'] {
    top: 19px;
  }

  .andes-badge,
  .andes-badge--notification {
    top: 2px;
  }
}
