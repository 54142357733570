.#{$pdp-prefix}-message {
  &__link {
    display: inline;
  }
}

.ui-vip-core--official-store {
  .#{$pdp-prefix}-message {
    margin: 16px 0;
  }
}

.ui-vip-core--cpg {
  .#{$pdp-prefix}-message {
    margin-top: 16px;
  }
}
