.ui-vpp-coupons {
  &__action.ui-pdp-media {
    margin-top: $andes-spacing-8;
  }

  &__awareness &__text,
  &--cursor-pointer {
    cursor: pointer;
  }

  &:not(:empty) {
    margin-top: $andes-spacing-24;
    margin-bottom: $andes-spacing-24;
  }
}
